/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable sonarjs/no-extra-arguments */
/* eslint-disable sonarjs/cognitive-complexity */
import { memo, useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { isIOS } from 'react-device-detect';
import { toast } from 'react-toastify';
import { SelectItem } from '@mantine/core';
import { Loader } from '@mantine/core';
import { Tooltip } from '@material-tailwind/react';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslate } from '@tolgee/react';
import api from '@/api/services/achievements';
import OnBoardingCommandCenterModal from '@/components/Achievements/OnBoardingCommandCenterModal';
import { Typography } from '@/components/Atoms';
import { commanderQueryKey } from '@/components/CommandCenter/LeftBox/ArmiesSection/ArmiseList';
import { CustomModal } from '@/components/Molecules';
import CompletedSvg from '@/components/Svg/CompletedSvg';
import { ITaskActionDataType } from '@/types/Achievements';
import { EAuthVarient } from '@/types/Authentication';
import useCountdown from '@/utils/getDurationFormatted';
import { useStore } from '@/zustand/store/store';
import { rewardConfig } from '../_taskCard2';
import ActionDetailItem from './components/ActionDetailItem';
import { ConfirmSectionInput, ConfirmSectionInputIProps, ConfirmSectionIProps } from './components/ConfirmSection';
import IconCount from './components/ItemHolder/IconCount';
import SubTaskCheckBox, { SubTaskCheckBoxHandle } from './components/ItemHolder/SubTaskCheckBox';
import SubTaskCheckItem from './components/ItemHolder/SubTaskCheckItem';
import { ConfirmSectionLoading, ContentSectionLoading, TaskOverviewLoading, TaskStatisticsLoading } from './components/SkeletonLoading';
import style from './TaskDetailModal.module.scss';
import { useAccount } from 'wagmi';
import React from 'react';
import TaskOverview from './components/TaskOverview';
import UserCounter from './components/UserCounter';
import ItemHolder from './components/ItemHolder';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';
import useConnectWallet from '@/hooks/common/useConnectWallet';

interface TaskDetailModalProps {
  taskId: string;
  onClose: () => void;
  role?: boolean;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const TaskDetailModal: React.FunctionComponent<TaskDetailModalProps> = memo(({ taskId, onClose, role }) => {
  const { t } = useTranslate('commandCenter');
  const { data, isLoading, isValidating, error, mutate } = api.useAchievementDetail({
    achievement_id: taskId,
  });
  const { address } = useAccount();
  const { isLogin } = useAuthStatuses();
  const { showRewardsModal, setAuthModalVarient, onBoardingData, setOnBoardingData } = useStore();
  const session = useSession();
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const allData = data?.data.data;
  const requirementsData = data?.data.data?.requirements;
  const achievementData = allData?.Achievement;
  const actionsData = allData?.Actions;
  const diffDateFormatted = useCountdown(
    achievementData?.start_time > new Date().getTime() / 1000 ? achievementData?.start_time * 1000 : achievementData?.end_time * 1000 || 0,
    true,
  );
  const isLocked = achievementData?.start_time > new Date().getTime() / 1000;
  const progress = Math.min((achievementData?.user_action_count / achievementData?.action_count) * 100, 100); //  0-100
  const [confrimSelect, setConfirmSelect] = useState<string>();
  const confirmSelectOptions: SelectItem[] = achievementData?.achievement_approval?.map((item) => ({
    value: item.referral_code,
    label: item.user_name,
  }));
  const [confrimSelectDisabled, setConfrimSelectDisabled] = useState(false);
  const [isMissed, setIsMissed] = useState(false);
  const [isLockedData, setIsLockedData] = useState(data?.data?.data?.Achievement.lock);
  const [updateRequirementData, setUpdateRequirementData] = useState([]);
  const router = useRouter();
  const rewardSection = useRef(null);
  const requirementSection = useRef(null);
  const claimButton = useRef(null);
  const { handleClickWallet } = useConnectWallet();
  // const setNewParams = useUpdateQueryParams();

  // CommanderQuery
  /* ************** */
  const { [commanderQueryKey]: commanderQuery } = router.query;
  const filteredCommanderQuery = typeof commanderQuery === 'string' ? commanderQuery : null;

  const selectedCommander =
    filteredCommanderQuery && achievementData?.achievement_approval.find((item) => item.user_id === filteredCommanderQuery).referral_code;
  useEffect(() => {
    if (selectedCommander) {
      setConfirmSelect(selectedCommander);
      setConfrimSelectDisabled(true);
    }
  }, [selectedCommander]);

  const updateQueryParams = (newParams) => {
    router
      .push(
        {
          pathname: router.pathname,
          query: { ...router.query, ...newParams },
        },
        undefined,
        { shallow: true },
      )
      .then(() => {
        onClose();
      });
  };

  function moveAllItems() {
    const targetArray = [];

    requirementsData?.forEach((requirement) => {
      // Move all items from the current 'items' array to the target array
      targetArray.push(...requirement.items);
    });

    return { movedItems: targetArray };
  }

  useEffect(() => {
    const { movedItems } = moveAllItems();
    setUpdateRequirementData(movedItems);
  }, [data?.data?.data?.requirements]);

  useEffect(() => {
    if (!data?.data?.data?.Achievement.available) {
      setIsMissed(true);
    }
  }, [data?.data?.data?.Achievement.available]);

  useEffect(() => {
    const now = Math.floor(Date.now() / 1000);

    if (
      data?.data?.data?.Achievement?.state !== 'completed' &&
      data?.data?.data?.Achievement.end_time !== 0 &&
      data?.data?.data?.Achievement.end_time < now
    ) {
      setIsMissed(true);
    } else {
      setIsMissed(false);
    }
  }, [data?.data?.data?.Achievement.end_time]);

  useEffect(() => {
    if (data?.data?.data?.Achievement.lock) {
      setIsLockedData(true);
    } else {
      setIsLockedData(false);
    }
  }, [data?.data?.data?.Achievement.lock]);

  const {
    commandSocketData: { stateChanged },
  } = useStore();
  useEffect(() => {
    if (isLogin) {
      let decodedAccessToken: string = jwt_decode(
        //@ts-ignore
        session?.data?.accessToken,
        //@ts-ignore
      ).token;
      setUserId(decodedAccessToken);
    }
  }, [isLogin]);

  useEffect(() => {
    if (stateChanged) {
      mutate();
    }
  }, [stateChanged]);

  const subTaskCheckItemRefList = useRef<Array<{ action: ITaskActionDataType; ref: SubTaskCheckBoxHandle }>>([]);

  const selectHandler = (value: string) => {
    setConfirmSelect(value);
  };

  const done = progress === 100;
  const published =
    role && achievementData?.achievement_approval && userId && achievementData?.achievement_approval?.find((item) => item.user_id === userId);

  const publishTitle = published ? 'Published' : 'Publish';
  const isGame = data?.data?.data?.Achievement?.cats?.find((c) => c.category_name === 'Game');

  const confirmUnderReview = actionsData?.find((item) => item.done_by_user && item.user_count < item.count) ? true : false;
  // const confirmUnderPrize = actionsData?.find(item => item.done_by_user && item.user_count >= item.count) ? true : false;
  const confirmUnderPrize = actionsData?.filter((item) => item.done_by_user && item.user_count >= item.count).length === actionsData?.length;
  let confirmTitle = 'CENTERECTION.achievementSection.button.confirm';
  if (loading) {
    confirmTitle = 'CENTERECTION.achievementSection.button.loading';
  } else if (role) {
    confirmTitle = publishTitle;
  } else if (done) {
    confirmTitle = 'CENTERECTION.achievementSection.button.complete';
  } else if (confirmUnderReview) {
    confirmTitle = 'CENTERECTION.achievementSection.button.review';
  } else if (confirmUnderPrize) {
    confirmTitle = 'CENTERECTION.achievementSection.button.process';
  }

  let confirmDisabled = false;
  let confirmDone = false;
  if (loading || published || done || confirmUnderPrize) {
    confirmDisabled = true;
  }
  if (published || done) {
    confirmDone = true;
  }

  const toastErrorFn = () => {
    toast.info('This command is endorsed by commanders. Choose your preferred commander to accept the command.', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        minWidth: '360px',
      },
    });
  };

  const toastPublishSuccess = () => {
    toast.success('Command Published Successfully.', {
      position: 'bottom-center',
      autoClose: 2000,
      closeOnClick: true,
      theme: 'dark',
    });
  };

  const confrimHandler = async (actionId: string) => {
    if (data?.data?.data?.Achievement?.category_name === 'Game') {
      router.push('/start-mining');
    }
    if (published || done) {
      return;
    }

    if (role) {
      if (!isLogin) {
        onClose();
        return setAuthModalVarient(EAuthVarient.SIGN_IN);
      }

      if (!achievementData) {
        return null;
      }

      if (published) {
        return;
      }

      setLoading(true);
      await api.approveTask({
        achievement_id: taskId,
        action_id: actionId,
      } as { achievement_id: string; action_id: string });
      toastPublishSuccess();
      await mutate();
      setLoading(false);
    }

    if (confirmSelectOptions && !confrimSelect) {
      toastErrorFn();
      return null;
    }

    subTaskCheckItemRefList?.current?.find(({ action }) => action.user_count < action.count)?.ref?.click();
  };

  // const hasNftOrLrtRequirement = updateRequirementData.find((i) => ['lrt', 'asset', 'blueprint'].includes(i.type));

  const renderClaimPrizeModal = async () => {
    // const hasNftOrLrt = achievementData?.Prizes.find((i) => ['lrt', 'asset', 'blueprint'].includes(i.type));
    // if (!address && hasNftOrLrt) {
    //   handleClickWallet();
    // }
    await api.claimAchievement({ achievement_id: taskId }).then((res) => {
      onClose();
    });
  };

  const confirmProps: (actionId: string) => ConfirmSectionIProps = (actionId) => ({
    state: data?.data?.data?.Actions.find((item) => item.id === actionId)?.state,
    commander_refferral:
      !role && confirmSelectOptions
        ? {
            onChange: selectHandler,
            value: confrimSelect,
            disabled: confrimSelectDisabled,
          }
        : undefined,
    onClick: () => confrimHandler(actionId), // Pass the actionId to confrimHandler
    title: confirmTitle,
    disabled: confirmDisabled,
    done: confirmDone,
    isMissed: isMissed,
    isLocked: data?.data?.data?.Achievement?.prize_claimed || !isLogin ? false : !data?.data?.data?.verify ? true : isLockedData,
    role: role,
    achievementId: taskId, // Pass achievement_id to confirmProps
    actionId: actionId, // Pass action_id dynamically
  });

  const confirmInputProps: ConfirmSectionInputIProps = {
    select: {
      data: confirmSelectOptions,
      value: confrimSelect,
      onChange: selectHandler,
    },
  };

  const requirements = actionsData?.flatMap((act) => act?.Requierments?.map((req) => req)).filter((item) => item);
  const hasTwitterTweet = actionsData?.some((item) => item.action_type.Name === 'twitter_tweet');
  const sortedActions = hasTwitterTweet ? [...actionsData]?.sort((a, b) => (a?.action_type?.Name === 'twitter_tweet' ? 1 : -1)) : actionsData;

  const isClaimState = achievementData?.state == 'claimable' || achievementData?.state == 'not_claimed';
  const isReferralSelectInput = !role && !isClaimState && achievementData?.state != 'completed' && confirmInputProps.select.data?.length > 0;
  return (
    <>
      <div className='relative'>
        <CustomModal
          visible={Boolean(taskId) && !showRewardsModal}
          onClose={onClose}
          zIndexInner={50}
          zIndexOverlay={49}
          bodyStyle={{
            background: 'linear-gradient(180deg, #141414 0%, #011C24 100%)',
            width: '100%',
            padding: '0',
            borderRadius: '1rem',
            overflow: 'auto',
          }}
          contentStyle={{ overflow: 'auto', borderRadius: '1rem', background: 'linear-gradient(180deg, #141414 0%, #011C24 100%)' }}
          centered
          fullScreen={isMobile || isTablet}
        >
          {!isLoading && !data && <div>No Data Found!</div>}

          <div className={style.modal}>
            <div className={style.statisticSection}>
              <div>
                {isLoading ? (
                  <TaskOverviewLoading />
                ) : (
                  <TaskOverview
                    isLocked={isLocked}
                    diffDateFormatted={diffDateFormatted}
                    image={achievementData?.img ? `${process.env.NEXT_PUBLIC_IMAGE_CDN3}/${achievementData?.img}` : '/images/default-command.jpg'}
                    title={achievementData?.name}
                    description={achievementData?.description}
                    progress={progress}
                    end_time={data?.data?.data?.Achievement.end_time}
                    winner={achievementData?.achievement_winner}
                  />
                )}
                {isLoading ? (
                  <TaskStatisticsLoading />
                ) : (
                  <>
                    <div className='flex justify-end w-full mt-12'>
                      {allData?.Achievement.Report.pool > 0 && (
                        <UserCounter current={allData.Achievement.Report.count} max={allData.Achievement.Report.pool} />
                      )}
                    </div>
                    {achievementData?.Prizes?.length > 0 && (
                      <div ref={rewardSection} className='bg-[#2A6E72]/[0.13] rounded-lg relative mt-2 border border-[#EBBD51]' id='reward-section'>
                        <Typography
                          size='s14ToS12'
                          className='text-[#2E2E2E] flex w-full justify-start items-start bg-gradient-to-r font-bold from-[#FFD852] to-[#FF9900] rounded-t-lg px-2 py-1'
                        >
                          {t('taskDetailModal.reward')}
                        </Typography>
                        {/* <div className='absolute bg-[url("/images/rewardBadge.png")] -top-6 right-5 bg-cover w-20 h-20  flex items-center justify-center'>
                          <Typography size='s12ToS12' className='text-black'>
                            {achievementData?.Prizes?.length}
                          </Typography>
                        </div> */}

                        <ItemHolder className='rounded-[6px] w-full grid grid-cols-2 gap-0 p-1'>
                          {achievementData?.Prizes?.map((item) =>
                            item.type in rewardConfig ? (
                              <IconCount
                                key={item.id}
                                count={item.amount}
                                isActive
                                icon={rewardConfig[item.type].icon}
                                imageSrc={item?.img || rewardConfig[item.type].image}
                                currency={''}
                                typeId={item.type_id}
                                type={item.type}
                                title={item?.reward_name || rewardConfig[item.type].name}
                              />
                            ) : null,
                          )}
                        </ItemHolder>
                      </div>
                    )}
                    <div className='bg-[#2A6E72]/[0.13] border border-[#15373C] rounded-lg relative mt-2' id='command-section'>
                      <Typography
                        size='s12ToS12'
                        className='text-[#ffffff] w-full justify-start items-start flex px-2 py-1 bg-gradient-to-r from-[#15373C] to-[#1B3032] rounded-t-lgk'
                      >
                        {t('taskDetailModal.subcommand')}{' '}
                      </Typography>

                      <ItemHolder className='rounded-b-[6px] flex flex-col w-full p-2 '>
                        {sortedActions?.map((item, index) => (
                          <SubTaskCheckItem
                            key={item.id}
                            achievementId={taskId}
                            referal_user_id={confrimSelect}
                            ref={(ref) => (subTaskCheckItemRefList.current[index] = { ref: ref, action: item })}
                            closeModal={onClose}
                            {...item}
                            state={item.state}
                            mutate={mutate}
                            isLocked={isLockedData}
                            isGame={!!isGame}
                            onClick={
                              // hasNftOrLrtRequirement && !address
                              //   ? handleClickWallet
                              // :
                              isMissed
                                ? () => {}
                                : (actionHandler) => {
                                    if (role) {
                                      return;
                                    }
                                    if (confirmSelectOptions && !confrimSelect) {
                                      toastErrorFn();
                                      return null;
                                    }
                                    actionHandler();
                                  }
                            }
                            confirmProps={confirmProps(item.id)} // Pass actionId to confirmProps
                          />
                        ))}
                        {isIOS && (
                          <div className='flex flex-col my-2'>
                            <Typography className='text-white text-[10px]'>{t('taskDetailModal.hintIOS')}</Typography>
                          </div>
                        )}
                      </ItemHolder>
                    </div>
                    {updateRequirementData?.length === 0 ||
                      (updateRequirementData !== null && (
                        <>
                          <div
                            className='bg-[#2A6E72]/[0.13] border border-[#15373C] rounded-lg relative mt-2'
                            id='requirement-section'
                            ref={requirementSection}
                          >
                            <Typography
                              size='s12ToS12'
                              className='text-[#ffffff] w-full justify-start items-start flex px-2 py-1 bg-gradient-to-r from-[#15373C] to-[#1B3032] rounded-t-lg'
                            >
                              {t('taskDetailModal.requirement')}
                            </Typography>
                            <ItemHolder className='rounded-b-[6px] w-full p-2 '>
                              {!isLoading && (
                                <>
                                  {requirementsData && requirementsData.length > 0 ? (
                                    requirementsData.map((requirementGroup, groupIndex) => (
                                      <React.Fragment key={groupIndex}>
                                        <div className='flex items-center flex-wrap '>
                                          {requirementGroup.items.map((req, itemIndex) => (
                                            <React.Fragment key={req.id}>
                                              {!['action', 'achievement', 'xp', 'lrt', 'fuel'].includes(req.type) ? (
                                                <Tooltip
                                                  content={
                                                    <div className='flex flex-col items-center'>
                                                      {/* {req.image && req.type !== 'blue_print' && (
                                                        <img
                                                          src={'https://cdn.landrocker.io/mp/assets/' + req.image}
                                                          alt='Requirement'
                                                          className='w-20 h-20 mb-2'
                                                        />
                                                      )} */}
                                                      {req.image &&
                                                      (req.type === 'blue_print' || req.type === 'asset_type' || req.type === 'collection') ? (
                                                        <img src={req.image} alt='Requirement' className='w-20 h-20 mb-2 object-cover' />
                                                      ) : (
                                                        <img
                                                          src={'https://cdn.landrocker.io/mp/assets/' + req.image}
                                                          alt='Requirement'
                                                          className='w-20 h-20 mb-2'
                                                        />
                                                      )}
                                                      {req.complete_name && (
                                                        <Typography size='s14ToS12' className='text-[#EBBD51] font-bold'>
                                                          {req.complete_name}
                                                        </Typography>
                                                      )}
                                                    </div>
                                                  }
                                                  animate={{
                                                    mount: { scale: 1, y: 0 },
                                                    unmount: { scale: 0, y: 25 },
                                                  }}
                                                  placeholder=''
                                                  onPointerEnterCapture={undefined}
                                                  onPointerLeaveCapture={undefined}
                                                >
                                                  <div>
                                                    <SubTaskCheckBox
                                                      passive
                                                      description={req.name}
                                                      status={req.status === 'done' ? 'completed' : 'start'}
                                                      isLocked={isLockedData}
                                                      count={req.amount !== null ? req.amount : 0}
                                                      userCount={req.user_amount}
                                                      amountType={req.amount_type}
                                                      name={''}
                                                    />
                                                  </div>
                                                </Tooltip>
                                              ) : (
                                                <div>
                                                  <SubTaskCheckBox
                                                    passive
                                                    description={req.name}
                                                    status={req.status === 'done' ? 'completed' : 'start'}
                                                    isLocked={isLockedData}
                                                    count={req.amount !== null ? req.amount : 0}
                                                    userCount={req.user_amount}
                                                    amountType={req.amount_type}
                                                    name={''}
                                                  />
                                                </div>
                                              )}
                                              {itemIndex < requirementGroup.items.length - 1 && (
                                                <Typography size='s14ToS12' className='mx-2 mb-2 text-[#EBBD51] font-bold'>
                                                  {requirementGroup.operator}
                                                </Typography>
                                              )}
                                            </React.Fragment>
                                          ))}
                                        </div>

                                        {/* Display the outer operator on a separate line between groups if it exists and is not the last group */}
                                        {groupIndex < requirementsData.length - 1 && data?.data?.data.main_operator && (
                                          <div className='w-full text-start justify-start flex items-center -mt-1 mb-1'>
                                            <Typography size='s14ToS12' className='text-[#EBBD51] font-bold capitalize'>
                                              {String(data?.data?.data?.main_operator)}
                                            </Typography>
                                          </div>
                                        )}
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    <div>No requirements found.</div>
                                  )}
                                </>
                              )}
                            </ItemHolder>
                          </div>
                        </>
                      ))}
                  </>
                )}
              </div>
              {isClaimState && (
                <Tooltip
                  content={t('tooltips.detail_claim')}
                  animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                  }}
                  placeholder=''
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <div
                    className={classNames(
                      'text-[#00E440] border border-[#00FF47] bg-[#0A5054]/[0.48] rounded-md justify-center items-center flex cursor-pointer hover:bg-[#00E440]/[0.20] transition-all',
                      onBoardingData.isShowHintClaimButton && style.pulse,
                    )}
                    onClick={() => renderClaimPrizeModal()}
                    id='claim-button'
                  >
                    Claim
                  </div>
                </Tooltip>
              )}
              {achievementData?.state == 'pending_claim' && (
                <Tooltip
                  content={t('tooltips.detail_pending')}
                  animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                  }}
                  placeholder=''
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <div className='bg-[#1AFFFF]/[0.2] text-[#1AFFFF] border border-[#1AFFFF] rounded-md justify-center items-center flex gap-2'>
                    <Loader size={20} color='cyan' />
                    Processing
                  </div>
                </Tooltip>
              )}
              {!isGame && achievementData?.state == 'completed' && (
                <Tooltip
                  content={t('tooltips.completed')}
                  animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                  }}
                  placeholder=''
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <div className='text-[#00E440] border border-[#00FF47] bg-[#0A5054]/[0.48] rounded-md justify-center items-center flex'>
                    <CompletedSvg />
                    {t('CENTER_SECTION.achievementSection.button.complete')}
                  </div>
                </Tooltip>
              )}
              {isReferralSelectInput && <>{!isMobile && isLoading ? <ConfirmSectionLoading /> : <ConfirmSectionInput {...confirmInputProps} />}</>}
            </div>
            <div className={style.contentSection} id='description-section'>
              {isLoading ? (
                <ContentSectionLoading />
              ) : (
                <div
                  className='mt-4 text-justify prose prose-p:text-white prose-headings:text-white dark:prose-invert
            prose-h1:font-bold prose-h1:text-xl max-w-[98%] prose-a:text-blue-600 prose-strong:text-gray-400 prose-img:rounded-xl prose-img:w-full prose-img:max-w-[450px] prose-img:mx-auto'
                >
                  {isLocked && (
                    <h1 className='text-white font-bold mb-10'>
                      <Typography size='s28ToS15' isBold>
                        {achievementData?.name}
                      </Typography>
                    </h1>
                  )}
                  {sortedActions?.map((item) => (
                    <ActionDetailItem {...item} key={item.id} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </CustomModal>
      </div>
      <OnBoardingCommandCenterModal
        rewardSection={rewardSection}
        claimButton={claimButton}
        state={achievementData?.state}
        requirementSection={requirementSection}
      />

      {isReferralSelectInput && <>{taskId && (isMobile || isTablet) && <ConfirmSectionInput {...confirmInputProps} />}</>}
    </>
  );
});
export default TaskDetailModal;
